define('bootstraps/enhanced/image-content',[
    'common/modules/gallery/lightbox'
], function (
    Lightbox
) {
    return {
        init: function () {
            Lightbox.init();
        }
    };
});

